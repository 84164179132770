@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  padding: 20px 40px;
  .container {
    width: 100%;
    .header {
      display: flex;
      justify-content: center;
      > div {
        > h1 {
          text-align: center;
          font-family: $system_semiBold_font;
          font-size: 25px;
          color: $black_text_color;
        }
        > h5 {
          text-align: center;
          font-family: $system_regular_font;
          font-size: 14px;
          color: $black_text_color;
          margin-top: 15px;
        }
      }
      .promoCodeWrapper {
        width: 55%;
        margin: 36px auto 0;
        max-width: 500px;
      }
    }
    .plansWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-top: 20px;
      justify-content: center;
    }
  }
}

@media (max-width: $break_point_sm) {
  .wrapper {
    .container {
      .header {
        .promoCodeWrapper {
          width: 80%;
        }
      }
      .plansWrapper {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (max-width: $break_point_760) {
  .wrapper {
    .container {
      .header {
        .promoCodeWrapper {
          margin: 46px auto 0;
        }
      }
    }
  }
}
