@import "../../helpers/variables.scss";
@import "../../helpers/fonts.scss";

.cardBody,
.bankBody {
  width: 260px;
  margin: 30px 0;
  position: relative;
  .arrowLeft {
    position: absolute;
    top: 60px;
    left: -35px;
  }
  .arrowRight {
    position: absolute;
    top: 60px;
    right: -35px;
  }
  > button {
    margin-top: 50px;
    width: 100%;
  }
  .buttonWithSmallMargin {
    padding: 0;
    button {
      width: 100%;
      margin-top: 15px;
    }
  }
}

.bankBody {
  > div {
    padding: 0 20px;
  }
  .arrowLeft {
    position: absolute;
    top: 60px;
    left: -55px;
  }
  .arrowRight {
    position: absolute;
    top: 60px;
    right: -55px;
  }
  > .noBankAccountWrapper {
    padding: 0;
    > div {
      height: 140px;
    }
    button {
      padding: 0;
      margin-top: 52px;
      width: 100%;
    }
  }

  .promoCodeMain {
    position: relative;
    margin-top: 45px;
    height: 38px;
    .promoCodeWrapper {
      position: absolute;
      width: 145%;
      left: -58px;
      padding: 0;
    }
  }
}

.noPaymentAccountAccountWrapper {
  height: 140px;
  padding: 0;
  position: relative;
  > div {
    > div {
      button {
        margin-top: 50px;
        width: 100%;
      }
    }
  }
}

@media (max-width: $break_point_lg) {
  .cardBody,
  .bankBody {
    .promoCodeMain {
      margin-top: 45px;
      height: 38px;
      .promoCodeWrapper {
        width: 120%;
        left: -25px;
        padding: 0;
      }
    }
  }
}

@media (max-width: $break_point_430) {
  .container {
    .cardBody,
    .bankBody {
      width: 240px;
    }
  }
}
