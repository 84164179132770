@import "../../helpers/fonts.scss";
@import "../../helpers/variables.scss";

.main {
  display: flex;
  min-height: 530px;
  margin: 0 10px 20px;
  width: 275px;
  border: 0.5px solid $light_cyan;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 4px 0px;
  padding: 0 0 10px 0;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: visible;
  padding: 30px 15px 15px;
  .block {
    .topBlock,
    .bottomBlock {
      border-bottom: 1px solid $light_cyan;
      margin-bottom: 30px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        > span {
          text-transform: uppercase;
          font-size: 15px;
          color: $cyan;
          line-height: 18px;
          font-family: $system_semiBold_font;
        }
        .tooltipWrapper {
          height: 24px;
          position: relative;
          .tooltip {
            top: -50px;
            left: 48px;
            position: absolute;
            padding: 30px;
            width: 380px;
            box-sizing: border-box;
            background-color: #ffffff;
            border-radius: 20px;
            border: 1px solid $light_cyan;
            z-index: 1;
            display: none;
            box-shadow: 4px 4px 4px 0px #00000040;
            .tooltipContainer {
              position: relative;
              .tooltipArrow {
                top: 19px;
                background-color: #fff;
                left: -43px;
                position: absolute;
                width: 25px;
                height: 25px;
                border-top: 1px solid $light_cyan;
                border-right: 1px solid $light_cyan;
                transform: rotate(-135deg);
              }
              > p {
                font-size: 14px;
                font-family: $system_regular_font;
                color: $black_text_color;
                margin: 0;
              }
            }
          }
          &:hover {
            .tooltip {
              display: block;
            }
          }
        }
        img {
          cursor: pointer;
        }
      }
      .body {
        padding-bottom: 5px;
        .line,
        .chargedLine {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          > span {
            overflow-wrap: break-word;
            &:first-child {
              width: 45%;
              color: $black_text_color;
              font-size: 14px;
              line-height: 17px;
              font-family: $system_semiBold_font;
            }
            &:last-child {
              width: 55%;
              color: $black_text_color;
              font-size: 14px;
              line-height: 17px;
              font-family: $system_regular_font;
            }
          }
        }
        .chargedLine {
          > span {
            &:first-child {
              color: $cyan;
            }
            &:last-child {
              color: $cyan;
            }
          }
        }
      }
    }
  }
  .totalSum {
    width: 100%;
    border-radius: 10px;
    border: 1px solid $cyan;
    padding: 12px 0;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    color: $cyan;
    font-family: $system_semiBold_font;
  }
}

@media (max-width: $break_point_md) {
  .main {
    .block {
      .topBlock,
      .bottomBlock {
        .header {
          .tooltipWrapper {
            &:hover {
              .tooltip {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
